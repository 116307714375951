<template>
  <div class="jdshop">
    <div>
      <div class="jdshop-navigate">
        <navbar-tools :leftArrow="true"></navbar-tools>
        <div class="search-content">
          <van-search
            v-model="pages.keyWords"
            placeholder="搜索商品名称"
            :clearable="false"
            @search="queryJdGoodsListFn"
          />
        </div>
        <div class="search-condition">
          <div class="search-types">
            <div class="types-item types-classfiy">
              <div class="types-list">
                <van-tabs
                  v-model:active="classfiyActive"
                  title-active-color="#FF4242"
                  @click="setOneCateIdFn"
                >
                  <van-tab title="全部" name=""></van-tab>
                  <van-tab
                    :title="item.categoryName"
                    v-for="item in conditionObject?.categoryList"
                    :key="item.categoryId"
                    :name="item.categoryId"
                  ></van-tab>
                </van-tabs>
              </div>
            </div>
            <div class="types-items">
              <img :src="OSS_URL + '/shop/classfiy.png'" @click="classfiyFn" />
            </div>
          </div>
          <div class="search-types-money">
            <van-tabs v-model:active="moneyActive" @click="setPriceIntervalIdFn">
              <van-tab title="" name=""></van-tab>
              <van-tab
                :title="item.priceIntervalName"
                v-for="item in conditionObject?.priceIntervalList"
                :key="item.priceIntervalId"
                :name="item.priceIntervalId"
              ></van-tab>
            </van-tabs>
          </div>
        </div>
      </div>
      <div class="shoplistBox" v-if="nullList">
        <van-list
          finished-text="没有更多了"
          :immediate-check="false"
          v-model:loading="loading"
          :finished="finished"
          @load="onloadFn"
        >
          <shoplist :shopList="jdGoodsList" :color="'ff4242'"></shoplist>
        </van-list>
      </div>
      <div v-else class="nullList">
        <img :src="OSS_URL + '/null.png'" />
      </div>
    </div>
  </div>
</template>

<script>
import navbarTools from "@/components/navbarTools";
import shoplist from "@/components/shoplist";
import { defineComponent, reactive, toRefs, getCurrentInstance, onActivated } from "vue";
import { useRouter, useRoute } from "vue-router";
import { jdHomeSearchCondition, jdGoodsList } from "@/api/shop";
export default defineComponent({
  name: "jdshop",
  components: {
    navbarTools,
    shoplist,
  },
  setup() {
    const $router = new useRouter();
    const $route = new useRoute();
    const state = reactive({
      classfiyActive: 0,
      moneyActive: "",
      OSS_URL: process.env.VUE_APP_OSS_URL,
      loading: false,
      finished: false,
      conditionObject: null,
      priceIntervalId: null,
      pages: {
        keyWords: null,
        oneCateId: null,
        page: 1,
        pageSize: 10,
        priceIntervalId: null,
        sortPrice: null,
        sortSales: null,
        threeCateId: null,
        twoCateId: null,
      },
      jdGoodsList: [],
      nullList:true
    });
    const classfiyFn = () => {
      $router.push({
        path: "/jdshop/classfiy",
      });
    };
    const jdHomeSearchConditionFn = async () => {
      let rs = await jdHomeSearchCondition();
      state.conditionObject = rs.data;
    };
    const jdGoodsListFn = async () => {
      let rs = await jdGoodsList(state.pages);
      state.jdGoodsList = [...state.jdGoodsList, ...rs.data.list];
      if (state.jdGoodsList.length > 0) {
        state.nullList = true;
      } else {
        state.nullList = false;
      }
      if (state.pages.page >= rs.data.totalpage) {
        state.finished = true;
      } else {
        state.loading = false;
      }
    };
    const queryJdGoodsListFn = async (e) => {
      if (state.pages.keyWords.trim()) {
        $router.push({
          path: "/jdshop/jdtwosearch",
          query: {
            text: state.pages.keyWords,
          },
        });
      }
    };
    const onloadFn = () => {
      setTimeout(() => {
        state.pages.page = state.pages.page + 1;
        jdGoodsListFn();
      }, 1000);
    };
    const setOneCateIdFn = (id) => {
      state.pages.oneCateId = id;
      state.pages.page = 1;
      state.jdGoodsList = [];
      state.loading = false;
      state.finished = false;
      jdGoodsListFn();
    };
    const setPriceIntervalIdFn = (id) => {
      if (state.pages.priceIntervalId == id) {
        state.moneyActive = "";
        state.pages.priceIntervalId = "";
      } else {
        state.pages.priceIntervalId = id;
      }
      state.loading = false;
      state.finished = false;
      state.pages.page = 1;
      state.jdGoodsList = [];
      jdGoodsListFn();
    };

    $router.afterEach((to, from) => {
      if (from.path == "/jdshop/goodsDetail") {
        to.meta.isUseCache = false;
      } else {
        to.meta.isUseCache = true;
      }
    });
    onActivated(() => {
      if ($route.meta.isUseCache) {
        state.loading = false;
        state.finished = false;
        state.pages.oneCateId = null;
        state.pages.priceIntervalId = null;
        state.moneyActive = null;
        state.classfiyActive = 0;
        state.pages.page = 1;
        state.pages.keyWords = null;
        state.jdGoodsList = [];
        jdHomeSearchConditionFn();
        jdGoodsListFn();
      }
    });
    return {
      ...toRefs(state),
      classfiyFn,
      jdHomeSearchConditionFn,
      jdGoodsListFn,
      onloadFn,
      setOneCateIdFn,
      setPriceIntervalIdFn,
      queryJdGoodsListFn,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep(.navbar) {
  width: 375px;
  .van-nav-bar {
    background: #ff4242;
    .van-nav-bar__left .van-icon {
      font-size: 24px;
      color: #fff;
    }
    .van-nav-bar__title {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
    }
  }
}
.jdshop {
  .jdshop-navigate {
    position: sticky;
    z-index: 999;
    top: 0;
  }
  background: #f2f2f2;
  height: 100%;
  .search-content {
    width: 100%;
    background: #ff4242;
    height: 70px;
    .van-search {
      background: #ff4242;
      ::v-deep(.van-search__content) {
        height: 32px;
        .van-icon {
          color: #666;
          font-size: 16px;
        }
      }
      input {
        color: #666;
        font-size: 14px;
      }
    }
  }
  .search-condition {
    height: 80px;
    background: #fff;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    top: -10px;
    .search-types {
      display: flex;
      ::v-deep(.types-classfiy) {
        .van-tabs {
          .van-tabs__nav {
            background: transparent;
            .van-tab {
              flex: none;
              font-size: 16px;
              color: #333;
              margin: 0px 12px;
              padding: 0px;
            }
          }

          .van-tabs__line {
            width: 18px;
            height: 4px;
          }
        }
      }
      .types-list {
        width: 295px;
      }
      .types-items {
        img {
          width: 80px;
          height: 45px;
        }
      }
    }
    .search-types-money {
      ::v-deep(.van-tab) {
        flex: none;
        margin: 0px 5px;
        padding: 0px;
        .van-tab__text {
          font-size: 12px;
          color: #333;
          background: #f6f6f6;
          padding: 2px 5px;
          border-radius: 4px;
        }
      }
      ::v-deep(.van-tab--active) {
        .van-tab__text {
          font-size: 12px;
          color: #fff;
          background: #ff4242;
          padding: 2px 5px;
          border-radius: 4px;
        }
      }
      ::v-deep(.van-tabs__line) {
        display: none;
      }
      ::v-deep(.van-tab:nth-child(1)) {
        position: absolute;
        opacity: 0;
      }
    }
  }
  .nullList {
    margin-top: 160px;
    text-align: center;
    img {
      width: 138px;
      height: 163px;
    }
  }
}
</style>
